<template>
    <v-app>
        <v-app-bar
            app
            color="333333"
            dark
        >
            <div class="d-flex align-center">
                <v-img
                    alt="DeRosa Collections"
                    class="shrink mr-2"
                    contain
                    src="https://derosacollections.fr/wp-content/uploads/2020/01/derosa-france.jpg"
                    transition="scale-transition"
                    width="40"
                />

                <span
                    class="shrink mt-1 hidden-sm-and-down"
                >DeRosa Collections France</span>
            </div>

            <v-spacer></v-spacer>

            <v-text-field
                label="VRP"
                outlined
                dense
                v-model="vrp"
                v-if="this.logged && this.user.type === 'vrp'"
            ></v-text-field>
            <v-toolbar-title v-else-if="this.logged">{{ this.user.customer }}</v-toolbar-title>

            <v-btn icon @click="logout" v-if="this.logged">
                <v-icon>mdi-exit-to-app</v-icon>
            </v-btn>
        </v-app-bar>

        <v-main>

            <v-container v-if="this.logged">
                <v-alert
                    type="success"
                    v-if="success"
                >Commande envoyée
                </v-alert>
                <v-alert
                    type="warning"
                    v-if="warning"
                >Impossible d'envoyer la commande pour le moment, elle sera ré-envoyée plus tard.
                </v-alert>

                <v-card
                    outlined
                    class="pad"
                    :loading="loading"
                    v-show="this.user.type === 'vrp'"
                >
                    <div>
                        <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateFormatted"
                                    label="Date"
                                    outlined
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    @blur="date = parseDate(dateFormatted)"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="date"
                                no-title
                                @input="menu1 = false"
                            ></v-date-picker>
                        </v-menu>
                    </div>

                    <div>
                        <v-text-field
                            label="Client"
                            outlined
                            dense
                            v-model="customer"
                        ></v-text-field>
                    </div>
                </v-card>


                <v-row>
                    <v-col cols="12" md="9">
                        <v-data-table
                            :headers="headers"
                            :items="products"
                            item-key="name"
                            :search="search"
                            show-expand
                            :custom-filter="filterOnlyCapsText"
                            :loading="loading"
                            :items-per-page="25"
                            :footer-props="{
                                itemsPerPageOptions: [5, 10, 25, 50, 100, -1],
                            }"
                        >
                            <template v-slot:top>
                                <v-text-field
                                    v-model="search"
                                    label="Rechercher"
                                    class="mx-4"
                                    outlined
                                    append-icon="mdi-magnify"
                                ></v-text-field>
                            </template>
                            <template v-slot:item.name="{ item }">
                                <v-row>
                                    <v-col cols="4">
                                        <v-img :src="item.image" max-width="100" class="mt-2 mb-2"></v-img>
                                    </v-col>
                                    <v-col cols="8" class="pt-10">{{ item.name }}</v-col>
                                </v-row>
                            </template>
                            <template v-slot:item.category="{ item }">
                                <v-chip>{{ item.category }}</v-chip>
                            </template>
                            <template v-slot:item.price="{ item }">
                                {{ item.price }} €
                            </template>
                            <template v-slot:item.quantity="{ item }">
                                <v-text-field
                                    v-model="item.wanted"
                                    label="Quantité"
                                    outlined
                                    type="number"
                                    dense
                                    :max="item.quantity"
                                    min="0"
                                    class="quantity__field"
                                ></v-text-field>
                            </template>
                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length">
                                    <v-row no-gutters>
                                        <v-col
                                            cols="2"
                                        >
                                            <v-img :src="item.image"></v-img>
                                        </v-col>
                                        <v-col
                                            cols="10"
                                        >
                                            <span v-html="item.description">{{ item.description }}</span>
                                        </v-col>
                                    </v-row>

                                </td>
                            </template>
                        </v-data-table>
                    </v-col>
                    <v-col cols="12" md="3">

                        <v-card outlined class="mt-6" :loading="loading">

                            <v-card-title>Votre commande</v-card-title>

                            <v-card-text>

                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                        <tr>
                                            <th class="text-left">
                                                UGS
                                            </th>
                                            <th class="text-left">
                                                Prix
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                            v-for="item in orderRecap"
                                            :key="item.sku"
                                        >
                                            <td>{{ item.sku }}
                                                <v-chip class="ml-3">{{ item.quantity }}</v-chip>
                                            </td>
                                            <td>{{ item.price }} €</td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>

                            </v-card-text>

                            <v-card-title>Total: {{ total }} €</v-card-title>

                            <v-card-text>
                                <div class="text-center mt-5 mb-5">
                                    <v-btn
                                        rounded
                                        color="333333"
                                        dark
                                        :loading="loading"
                                        @click="send"
                                        style="max-width: 100%;"
                                    >
                                        Envoyer le bon de commande
                                    </v-btn>
                                </div>
                            </v-card-text>


                        </v-card>

                        <div class="text-right mt-5 mb-5">
                            <v-btn disabled color="333333" fab>
                                <v-icon>mdi-printer</v-icon>
                            </v-btn>
                        </div>

                    </v-col>
                </v-row>

            </v-container>

            <v-container v-else>
                <v-card
                    class="mx-auto"
                    max-width="444"
                    outlined
                    :loading="loading"
                >
                    <v-list-item three-line>
                        <v-list-item-content>

                            <div>
                                <v-alert
                                    type="error"
                                    v-if="error"
                                >Nom d'utilisateur ou mot de passe incorrect
                                </v-alert>
                            </div>

                            <div>
                                <v-img
                                    alt="DeRosa Collections"
                                    class="shrink mr-2"
                                    contain
                                    src="https://derosacollections.fr/wp-content/uploads/2020/01/derosa-france.jpg"
                                    transition="scale-transition"
                                    width="100"
                                    style="margin-left:auto;margin-right:auto !important;"
                                />
                            </div>

                            <div>
                                <form action="" v-on:submit.prevent="login">
                                    <v-text-field
                                        v-model="username"
                                        label="Nom d'utilisateur"
                                        outlined
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="password"
                                        label="Mot de passe"
                                        outlined
                                        type="password"
                                    ></v-text-field>

                                    <div class="text-right mt-5 mb-5">
                                        <v-btn
                                            rounded
                                            color="333333"
                                            dark
                                            :loading="loading"
                                            type="submit"
                                        >
                                            Se connecter
                                        </v-btn>
                                    </div>
                                </form>
                            </div>

                        </v-list-item-content>
                    </v-list-item>


                </v-card>
            </v-container>

        </v-main>
    </v-app>
</template>

<script>

import Math from "@/Helpers/Math";

// const BASE = "https://woocommerce-dev.stantabcorp.eu";
const BASE = "https://derosacollections.fr";
export default {
    name: 'App',
    mounted() {
        this.user = this.$ls.get('user');
        if (this.user == null) {
            this.logged = false;
        }
        if (this.logged) {
            this.load();
        }

    },
    data: function () {
        return {
            vrp: this.$ls.get('vrp'),
            search: '',
            customer: '',
            loading: false,
            products: [],
            total: 0,
            success: false,
            warning: false,
            date: new Date().toISOString().substr(0, 10),
            dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
            menu1: false,

            username: null,
            password: null,
            logged: this.$ls.get('user') != null,
            error: false,
            user: null,

            orderRecap: [],

        }
    },
    computed: {
        computedDateFormatted() {
            return this.formatDate(this.date)
        },
        headers() {
            return [
                {
                    text: 'Nom',
                    align: 'start',
                    sortable: true,
                    value: 'name',
                },
                {
                    text: 'UGS',
                    sortable: true,
                    value: 'sku',
                },
                {
                    text: 'Catégorie',
                    sortable: true,
                    value: 'category',
                },
                {
                    text: 'Prix',
                    sortable: true,
                    value: 'price',
                },
                {
                    text: 'Quantité',
                    sortable: false,
                    value: 'quantity',
                },
            ]
        },
    },
    methods: {
        filterOnlyCapsText(value, search) {
            return value != null &&
                search != null &&
                typeof value === 'string' &&
                value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
        },
        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate(date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        load() {
            this.loading = true;
            this.user = this.$ls.get('user');
            this.$axios.get(BASE + "/wp-json/wc/v2/b2b/products?authorization=" + this.user.token)
                .then(response => {
                    this.products = response.data.products;
                    this.loading = false

                    this.$ls.set('products', this.products);

                    if (this.$ls.get('orders') != null) {
                        let orders = [];
                        for (let i = 0; i < this.$ls.get('orders'); i++) {
                            const element = this.$ls.get('orders')[i];
                            this.$axios.post(BASE + "/wp-json/wc/v2/b2b/order?authorization=" + this.user.token, element).then(() => {
                            }).catch(() => {
                                orders.push(element)
                            })
                        }
                        this.$ls.set('orders', orders);
                    }

                }).catch(() => {
                this.products = this.$ls.get('products') == null ? [] : this.$ls.get('products');
            })
        },
        login() {
            this.loading = true;
            this.error = false;
            this.$axios.post(BASE + "/wp-json/wc/v2/b2b/login", {
                username: this.username,
                password: this.password,
            }).then(response => {
                this.username = null;
                this.password = null;
                this.logged = true;
                this.user = response.data
                this.$ls.set('user', response.data, 1036800000);
                this.load();
            }).catch(() => {
                this.error = true;
                this.loading = false;
            })
        },
        logout() {
            this.user = null;
            this.$ls.set('user', null)
            this.logged = false;
        },
        send() {
            this.loading = true;
            this.success = false;
            this.warning = false;
            this.total = 0;
            let products = [];
            for (let i = 0; i < this.products.length; i++) {
                const element = this.products[i];
                if (element.wanted !== undefined && element.wanted > 0) {
                    products.push({
                        sku: element.sku,
                        name: element.name,
                        quantity: element.wanted,
                        price: element.price
                    })
                    this.total = Math.ceil10(this.total + (element.price.replace(",", ".") * element.wanted), -2);
                }
            }

            this.user = this.$ls.get('user');
            if (this.user != null && this.user.type === 'customer') {
                this.customer = this.user.customer;
                this.vrp = "web";
                const date = new Date();
                this.date = date.getDate().toString().padStart(2, '0') + "/" + (date.getMonth() + 1).toString().padStart(2, '0') + "/" + date.getFullYear();
            }

            this.$axios.post(BASE + "/wp-json/wc/v2/b2b/order?authorization=" + this.user.token, {
                client: this.customer,
                date: this.date,
                vrp: this.vrp,
                products: products
            }).then(() => {
                this.success = true;
                this.loading = false;
            }).catch(() => {
                this.warning = true;
                this.loading = false;
                let previousOrders = this.$ls.get('orders') == null ? [] : this.$ls.get('orders');
                previousOrders.push({
                    client: this.customer,
                    date: this.date,
                    vrp: this.vrp,
                    products: products
                })
                this.$ls.set('orders', previousOrders);
            })
        }
    },
    watch: {
        products: {
            handler(newProducts) {
                this.total = 0;
                this.orderRecap = [];
                for (let i = 0; i < newProducts.length; i++) {
                    const element = newProducts[i];
                    if (element.wanted !== undefined && element.wanted > 0) {
                        this.total = Math.ceil10(this.total + (element.price.replace(",", ".") * element.wanted), -2);
                        this.orderRecap.push({
                            sku: element.sku,
                            quantity: element.wanted,
                            price: Math.ceil10(element.price.replace(",", ".") * element.wanted, -2)
                        })
                    }
                }
            },
            deep: true
        },
        vrp: function (newVrp) {
            this.$ls.set('vrp', newVrp)
        },
        date() {
            this.dateFormatted = this.formatDate(this.date)
        },
    },
};
</script>

<style>
.v-app-bar .v-input {
    max-width: 300px;
}

.v-input {
    margin-top: 25px !important;
}

.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    box-shadow: none !important;
}

.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content .col-10 {
    padding: 20px;
}

.pad {
    margin: 15px;
    padding-right: 15px;
    padding-left: 15px;
}
</style>
