import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import Storage from 'vue-ls';
import axios from 'axios'

Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.use(Storage, {
  namespace: 'derosa__',
});

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  Vue,
  dsn: "https://2cf30ef6b2674dbb8909fdd7d8aa2e4f@sentry.stantabcorp.net/11",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
